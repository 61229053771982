<template>
  <div class="user_index">
    <page-header :title="$tc('member.name', 2)">
    </page-header>
    <page-content>
      <ntm-block>
        <template slot="options">
<!--          <input type="text" class="form-control" :placeholder="$t('search')" v-model="search">-->
          <button @click="inviteModal()" type="button" class="btn btn-sm btn-success">{{ $t('member.add') }}</button>
        </template>
        <div class="table-responsive">
          <ntm-table :show-columns="showColumns" :data="model" @pageChange="setPage" :actions="true">
            <template slot="actions" slot-scope="{row}">
              <button v-if="user.uuid !== row.uuid && (user.roles.includes('SUPER_ADMIN') || user.roles.includes('ADMIN'))" @click="deleteMember(row.uuid)" class="btn btn-sm btn-danger">
                <i class="fa fa-trash"></i>
              </button>
            </template>
          </ntm-table>
        </div>
      </ntm-block>
    </page-content>
    <modal name="invite-user" :adaptive="true" height="auto">
      <form @submit.prevent="sendInvite()">
        <ntm-block>
          <formly-form :form="invite.form" :model="invite.model" :fields="invite.fields"></formly-form>
          <div class="d-flex mb-2 justify-content-center">
            <button class="btn btn-success push-15 mr-2" :disabled="invite.loading"><span
              v-if="!invite.loading">{{ $t('invite.send') }}</span><i v-if="invite.loading"
                                                        class="fa fa-cog fa-spin"></i></button>
          </div>
        </ntm-block>

      </form>
    </modal>
  </div>
</template>
<script>
import basicIndexMixin from '@/mixins/basic.index.mixin'
import MemberService from '@/services/member.service'
import InviteService from '@/services/invite.service'

export default {
  mixins: [basicIndexMixin],
  data () {
    return {
      invite: {
        loading: false,
        form: {},
        fields: [
          {
            key: 'email',
            type: 'input',
            templateOptions: {
              label: 'email'
            }
          },
          {
            key: 'role',
            type: 'ntm-select',
            options: ['ADMIN', 'MANAGER', 'SCOREKEEPER'],
            isString: true,
            templateOptions: {
              translated: true,
              label: 'role'
            }
          }
        ],
        model: {
          email: null,
          role: null
        }
      },
      showColumns: [
        {
          name: 'name',
          prop: 'name'
        },
        {
          name: 'email',
          prop: 'email'
        },
        {
          name: 'role',
          prop: 'roles',
          type: 'options'
        }
      ]
    }
  },
  beforeRouteEnter (to, from, next) {
    MemberService.index(1).then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    deleteMember (uuid) {
      MemberService.delete(uuid).then(() => {
        this.fetchData()
      })
    },
    inviteModal () {
      this.$modal.show('invite-user')
    },
    sendInvite () {
      this.invite.loading = true

      InviteService.send(this.invite.model).then((res) => {
        this.$modal.hide('invite-user')

        this.invite.model = {
          email: null,
          role: null
        }

        if (res.data === 'member.added') {
          this.fetchData()
        }
      }).catch((err) => {
        this.invite.form.$errors = err.response.data.errors
      }).finally(() => {
        this.invite.loading = false
      })
    },
    fetchData () {
      MemberService.index(this.page).then((response) => {
        this.setData(response.data)
      })
    }
  }
}
</script>
