import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from '@/views/Dashboard.vue'
import Login from '@/views/auth/Login.vue'
import { TokenService } from '@/services/storage.service'
import PlayerRoutes from './players'
import CoachRoutes from './coaches'
import ClubRoutes from './clubs'
import LeagueRoutes from './leagues'
import SeasonRoutes from './seasons'
import VenueRoutes from './venues'
import NewsRoutes from './news'
import ScheduleRoutes from './schedules'
import SettingsRoutes from './settings'
import ImageRequestRoutes from './imageRequests'
import MemberRoutes from './members'
import DocumentRoutes from './documents'
import SponsorRoutes from './sponsors'
import ActivityRoutes from './activities'
import DebtRoutes from './debts'
import store from '@/store'
import Default from '../layouts/Default'
import Auth from '../layouts/Auth'
import PenaltyRoutes from '@/router/penalties'
import Resolve from '@/views/Resolve.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/auth',
      name: 'auth',
      component: Auth,
      children: [
        {
          path: 'login',
          name: 'login',
          component: Login,
          meta: {
            public: true,
            onlyWhenLoggedOut: true
          }
        }
      ]
    },
    {
      path: '',
      name: 'admin',
      component: Default,
      beforeRouteUpdate: () => {
        store.commit('loaded')
      },
      children: [
        {
          path: '/',
          name: 'resolve',
          component: Resolve
        },
        {
          path: ':clientId/dashboard',
          name: 'dashboard',
          component: Dashboard
        },
        ...PlayerRoutes,
        ...CoachRoutes,
        ...ClubRoutes,
        ...LeagueRoutes,
        ...SeasonRoutes,
        ...VenueRoutes,
        ...MemberRoutes,
        ...ScheduleRoutes,
        ...NewsRoutes,
        ...SettingsRoutes,
        ...ImageRequestRoutes,
        ...DocumentRoutes,
        ...SponsorRoutes,
        ...DebtRoutes,
        ...PenaltyRoutes,
        ...ActivityRoutes
      ]
    }
  ],
  scrollBehavior () {
    const layoutId = document.getElementById('page-container')

    if (layoutId) {
      layoutId.scrollTo(0, 0)
    }
  }
})

router.beforeEach(async (to, from, next) => {
  // await store.dispatch('setClient', value)
  // eslint-disable-next-line
  jQuery('.modal').modal('hide')
  store.commit('loading')
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn = !!TokenService.getToken()
  if (!isPublic && !loggedIn) {
    return next({
      name: 'login',
      query: { redirect: to.fullPath }
      // Store the full path to redirect the user to after login
    })
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/')
  }

  next()
})

router.afterEach(() => {
  // eslint-disable-next-line
  store.dispatch('closeNav')
  store.commit('loaded')
})

export default router
