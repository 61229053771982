const fields = [
  {
    key: 'weeklyGamePredictionLimit',
    type: 'input',
    templateOptions: {
      label: 'weeklyGamePredictionLimit',
      attrs: {
        type: 'number'
      }
    }
  },
  {
    key: 'starterPlayersCount',
    type: 'input',
    templateOptions: {
      label: 'starterPlayersCount',
      attrs: {
        type: 'number'
      }
    }
  },
  {
    key: 'benchPlayersCount',
    type: 'input',
    templateOptions: {
      label: 'benchPlayersCount',
      attrs: {
        type: 'number'
      }
    }
  },
  {
    key: 'minPlayerValue',
    type: 'input',
    templateOptions: {
      label: 'minPlayerValue',
      attrs: {
        type: 'number',
        step: 0.1
      }
    }
  },
  {
    key: 'teamPredictionScoreMultiplier',
    type: 'input',
    templateOptions: {
      label: 'teamPredictionScoreMultiplier',
      attrs: {
        type: 'number',
        step: 0.1
      }
    }
  },
  {
    key: 'teamPredictionScoreMinValue',
    type: 'input',
    templateOptions: {
      label: 'teamPredictionScoreMinValue',
      attrs: {
        type: 'number',
        step: 0.1
      }
    }
  },
  {
    key: 'shouldCalculateMaxRosterValueByGame',
    type: 'boolean',
    templateOptions: {
      label: 'shouldCalculateMaxRosterValueByGame'
    }
  }
]
export default fields
